import { handleActions } from 'redux-actions';
import { GET_TASKS, GET_STATUS, GET_ORDERS_TASK, GET_MAP_MARKERS, POST_ASSIGN_ME_TASK } from '../../actionTypes/tasks';

const initialState = {
  loading: true,
  listTasks: [],
  listStatus: [],
  listOrdersTask: [],
  listMarkers: [],
}

const tasks = handleActions({
  [GET_TASKS](state, action) {
    return {
      ...state,
      listTasks: action.payload,
      loading: false
    }
  },
  [GET_STATUS](state, action) {
    return {
      ...state,
      listStatus: action.payload,
      loading: false
    }
  },
  [GET_ORDERS_TASK](state, action) {
    return {
      ...state,
      listOrdersTask: action.payload,
      loading: false
    }
  },
  [GET_MAP_MARKERS](state, action) {
    return {
      ...state,
      listMarkers: action.payload,
      loading: false
    }
  },
  [POST_ASSIGN_ME_TASK](state, action) {
    let newItem = action.payload;
    return {
      ...state,
      listTasks: state.listTasks.map(t => {
        if (t.id == action.payload.id) {
          return {
            ...t,
            status: newItem.status,
            assigned_to: newItem.assigned_to,
            assigned_to_date: newItem.assigned_to_date,
            assigned_to_descr: newItem.assigned_to_descr
          };
        } else {
          return t;
        }
      }),
      loading: false
    }
  }

}, initialState);

export default tasks
