import ReactDOM from "reactDOM";
import React from "react";
import helpers from "../../utils/helpers";
import Translate from "../shared/i18n/Translate";
import api from "../../utils/api";
import ReactTooltip from "react-tooltip";
import DetailsView from "./DetailsView";
import TimelineView from "./TimelineView";
import AssignDeviceView from "./AssignDeviceView";
import Alert from "../common/alert";
import Messages from "./Messages";
import "antd/lib/tabs/style/css";

var DevicePresentation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.locateMap = this.locateMap.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getDevice(this.props.deviceId)
      .then(() => this.setState({ loading: false }))
      .then(() => this.locateMap());
  }

  locateMap() {
    const { lat, lon } = this.props.device;
    var latLng = { lat: lat, lng: lon };
    var map = new google.maps.Map(this.map, {
      zoom: 4,
      center: latLng,
      disableDefaultUI: true,
      gestureHandling: "greedy",
    });

    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      title: "",
    });
  }
  getSourceType(type) {
    if (type == "1") return "LoRa";
    if (type == "2") return "Socket";
    if (type == "3") return "Sigfox";
    if (type == "4") return "Narrowband";
    if (type == "5") return "Mqtt Queue";
    return "-";
  }
  pushControlMenu() {
    var body = document.body;
    if (body.className.indexOf("control-sidebar-open") === -1) {
      body.className += " control-sidebar-open";
    } else {
      body.className = body.className.replace(" control-sidebar-open", "");
    }
  }
  componentWillUnmount() {
    if (body.className.indexOf("control-sidebar-open") !== -1)
      body.className = body.className.replace(" control-sidebar-open", "");
  }
  renderStatus(device) {
    if (device.status == "Online") {
      return <i className='fa fa-check-square-o  working'></i>;
    }
    return (
      <div>
        <a data-tip data-for='iconNotWorkin'>
          <i className='fa fa-times-circle-o err'></i>
        </a>
        <ReactTooltip id='iconNotWorkin'>
          <span>The device must report every {device.frecuency} secs.</span>
        </ReactTooltip>
      </div>
    );
  }
  render() {
    const {
      device,
      deviceView,
      deviceId,
      goToHome,
      goToDevices,
      getDevice,
      strings,
      loggedUser,
      setDeviceView,
    } = this.props;

    if (this.state.loading) return null;

    var Breadcrumb = ({ strings }) => (
      <ol
        className='breadcrumb'
        style={{ maxHeight: "30px", marginBottom: "3px" }}
      >
        <li>
          <div onClick={goToHome} style={{ cursor: "pointer" }}>
            <i className='fa fa-dashboard'></i> {strings.home}
          </div>
        </li>
        <li onClick={goToDevices} style={{ cursor: "pointer" }}>
          {strings.devices}
        </li>
        <li className='active'>{this.props.deviceId}</li>
      </ol>
    );
    Breadcrumb = Translate(Breadcrumb, "Breadcrumb");

    //var DeviceView = deviceView !== "details" ? Messages : DetailsView;
    var DeviceView;
    var content = {};
    if (deviceView == "details") {
      DeviceView = DetailsView;
    }

    if (deviceView == "messages") {
      DeviceView = Messages;
      content = {
        title: strings.messages,
        fetchMessages: (id, offset, limit) =>
          api.getDeviceMessages(id, offset, limit),
      };
    }
    if (deviceView == "reading")
      content = {
        title: strings.reading,
        fetchMessages: (id, offset, limit) =>
          api.getDeviceReading(id, offset, limit),
      };
    if (deviceView == "information")
      content = {
        title: strings.information,
        fetchMessages: (id, offset, limit) =>
          api.getDeviceInformation(id, offset, limit),
      };
    if (deviceView == "debug")
      content = {
        title: strings.debug,
        fetchMessages: (id, offset, limit) =>
          api.getDeviceDebug(id, offset, limit),
      };
    if (deviceView == "engineering")
      content = {
        title: strings.engineering,
        fetchMessages: (id, offset, limit) =>
          api.getDeviceEngineering(id, offset, limit),
      };
    if (deviceView == "timeline") {
      DeviceView = TimelineView;
      content = {
        title: "Timeline",
        fetchMessages: null,
      };
    }
    if (deviceView == "assignDevice") {
      DeviceView = AssignDeviceView;
      content = {
        title: "Assign Device",
        fetchMessages: null,
      };
    }

    return (
      <div style={{ height: "100%" }}>
        {(device.hasReadingIssues24hs || device.hasHelthIssues24hs) && (
          <Alert
            desc={
              device.hasReadingIssues24hs
                ? "The device has had invalid readings in the last 24 hours."
                : "The device has reported in the last 24 hours on health issues that need attention."
            }
            type='warning'
            closable={true}
            className='top-alert'
          />
        )}
        <section className='content-header'>
          <Breadcrumb />
          <h1 style={{ marginBottom: "15px" }}>
            {"Device " + deviceId}
            <small></small>
          </h1>
        </section>
        <div className='col-md-12'>
          <div className='box box-info'>
            <div
              className='box box-widget widget-user'
              style={{ marginBottom: "0px", boxShadow: "none" }}
            >
              <div
                className='widget-user-header'
                style={{
                  borderRadius: "0",
                  background: "white",
                  height: "130px",
                  padding: "0px",
                  borderBottom: "solid 1px rgb(179, 179, 179)",
                }}
              >
                <div ref={(i) => (this.map = i)} style={{ height: "100%" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    right: "12px",
                    top: "12px",
                  }}
                >
                  <div className='deviceInfo'>
                    <div>{strings.sn + ": " + deviceId}</div>
                    <div>{strings.name + ": " + device.descr}</div>
                    <div>{strings.type + ": " + device.device_type}</div>
                    <div>
                      {strings.source +
                        ": " +
                        this.getSourceType(device.communication_type)}
                    </div>
                    <div>{strings.gateway + ": " + device.gateway_guid}</div>
                  </div>
                  <a
                    data-toggle='control-sidebar'
                    style={{ padding: "initial" }}
                  >
                    <i
                      className='fa fa-gears devicePanelIcon'
                      onClick={this.pushControlMenu}
                      style={{ padding: "15px" }}
                    ></i>
                  </a>
                </div>
              </div>
              <div
                className='widget-user-image'
                style={{ top: "67px", marginLeft: "-64px" }}
              >
                <img
                  className='img-circle'
                  src='/dist/img/iotlogo.png'
                  alt='IoT'
                  style={{ border: "solid 1px #b3b3b3", width: "125px" }}
                />
              </div>
            </div>

            <div className='deviceContainer'>
              <div
                style={{ borderRight: "solid 1px #b3b3b3", width: "80%" }}
                className='deviceHeaderItem'
              >
                <div>{strings.battery}</div>
                <div>{device.battery + "V"}</div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "6px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      marginRight: "5px",
                      fontWeigth: "600",
                    }}
                  >
                    {"Inst. Date"}
                  </div>
                  <div style={{ fontSize: "16px", fontWeigth: "600" }}>
                    {helpers._transformUtcToTimezone(
                      device.installDate,
                      loggedUser.preferences.timezone,
                      true
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRight: "solid 1px #b3b3b3",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
                className='deviceHeaderItem'
              >
                <div>
                  {"Total Msg. " +
                    (device.cantMessages ? device.cantMessages : "N/A")}
                </div>
              </div>

              <div className='deviceHeaderItem'>
                <div className='icon'>{this.renderStatus(device)}</div>
                <div className='last-read'>
                  <div style={{ fontSize: "28px" }}>
                    <span className='last-read-text last-read-value'>
                      {device.last_reading} mm
                    </span>
                  </div>
                  <div className='last-read__report'>
                    <span className='last-read-text last-read-desc'>
                      {device.last_reading_status_description}
                    </span>
                  </div>
                  <div style={{ fontSize: "16px" }}>{"Last Report"}</div>
                  <div style={{ fontSize: "16px", lineHeight: "12px" }}>
                    {helpers._transformUtcToTimezone(
                      device.last_msg_time,
                      loggedUser.preferences.timezone,
                      true
                    )}
                  </div>
                </div>
              </div>
            </div>

            <DeviceView
              {...{
                loggedUser,
                content,
                strings,
                device,
                deviceId,
                setDeviceView,
                goToDevices,
              }}
              getMetricsDevice={this.props.getMetricsDevice}
              getMetricsDeviceV2={this.props.getMetricsDeviceV2}
              listMetricsDevice={this.props.listMetricsDevice}
              getHelthMetrics={this.props.getHelthMetrics}
              listHelthMetrics={this.props.listHelthMetrics}
              getReadQualityMetrics={this.props.getReadQualityMetrics}
              listReadQualityMetrics={this.props.listReadQualityMetrics}
            />

            <div className='box-footer' style={{ textAlign: "center" }}>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Translate(DevicePresentation, "Device");
