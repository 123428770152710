import React, { useState } from 'react';

const LabeledValue = ({ label, value, unit }) => (
  <h3 style={{ textAlign: 'left', width: '50%', marginTop: '20px' }}>
    {label}
    <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
      {value} {unit}
    </span>
  </h3>
);

const DipstickCalibration = (props) => {
  if (!props.asset) return null;

  const { asset, setAssetView } = props;
  const [dipstick, setDipstick] = useState(parseFloat(asset.dipstick) || 0);

  const deviceKey = Object.keys(asset.SensorStatus.devices)[0];
  const tankHeight = asset.assetType.waterTank_config.theigth || 0;
  const deviceDistance = (asset.SensorStatus.devices[deviceKey].sensorLastReadingValue || 0) / 10;
  const deviceLecture = tankHeight - deviceDistance;
  const adjustment = deviceLecture - dipstick;
  const sensorDepthOffset = asset.sensors.sensors[0].depthOffset || 0;
  const suggestedOffset = adjustment * 10;

  return (
    <div>
      <div className="row">
        <section className="col-lg-12 connectedSortable ui-sortable">
          <div className="box box-info" style={{ padding: '20px' }}>
            <div className="box-header ui-sortable-handle" style={{ cursor: 'move' }}>
              <i className="fa fa-area-chart" />
              <h3 className="box-title">Dipstick / Device Offset Calibration</h3>
              <div className="pull-right box-tools"></div>
            </div>
            <div className="box-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <LabeledValue label="The configured tank height is" value={tankHeight} unit="cm" />
              <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginTop: '10px' }}>
                <h3 style={{ marginRight: '10px', textAlign: 'left' }}>
                  Please enter the dipstick measurement (bottom to liquid) in cm
                </h3>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Dipstick"
                  name="dipstick"
                  onChange={(evt) => setDipstick(parseFloat(evt.target.value) || 0)}
                  style={{ maxWidth: '100px', marginLeft: '20px' }}
                />
              </div>
              <LabeledValue label="The last device distance from top to liquid was" value={deviceDistance} unit="cm" />
              <div style={{ textAlign: 'left', width: '50%', marginTop: '10px' }}>
                <LabeledValue label="Dipstick lecture" value={dipstick} unit="cm" />
                <LabeledValue label="Device lecture" value={deviceLecture} unit="cm" />
                <LabeledValue label="Adjustment" value={adjustment} unit="cm" />
                <LabeledValue label="The actual offset is" value={sensorDepthOffset} unit="mm" />
                <LabeledValue label="The suggested offset is" value={suggestedOffset} unit="mm" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button type="button" className="btn btn-block btn-primary" style={{ width: '100px' }} onClick={() => setAssetView("details")}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DipstickCalibration
