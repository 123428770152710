export const SET_ASSET = "app/assetSelected/SET_ASSET";
export const MERGE_ASSET = "app/assetSelected/MERGE_ASSET";
export const SET_ASSET_VIEW = "app/assetSelected/SET_ASSET_VIEW";
export const GET_HISTORICAL_METRICS =
  "app/assetSelected/GET_HISTORICAL_METRICS";
export const GET_REFILL_METRICS = "app/assetSelected/GET_REFILL_METRICS";
export const GET_TEMPERATURE_METRICS =
  "app/assetSelected/GET_TEMPERATURE_METRICS";
export const GET_TILT_METRICS = "app/assetSelected/GET_TILT_METRICS";
export const GET_ANALYTICS = "app/assetSelected/GET_ANALYTICS";
export const SET_ASSET_STATUS = "app/assetSelected/SET_ASSET_STATUS";
