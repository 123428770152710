import { handleActions } from "redux-actions";
import {
  SET_ASSET,
  MERGE_ASSET,
  SET_ASSET_VIEW,
  GET_HISTORICAL_METRICS,
  GET_TEMPERATURE_METRICS,
  GET_TILT_METRICS,
  GET_ANALYTICS,
  SET_ASSET_STATUS,
  GET_REFILL_METRICS,
} from "../actionTypes/assetSelectedActions.js";
var update = require("immutability-helper");

const initialState = {
  assetSelected: null,
  assetView: "details",
  historicalMetrics: [],
  refillMetrics: [],
  temperatureMetrics: [],
  tiltMetrics: [],
  analyticsMetrics: [],
};

const reducer = handleActions(
  {
    [SET_ASSET]: (state, action) =>
      update(state, { assetSelected: { $set: action.payload } }),
    [MERGE_ASSET]: (state, action) =>
      update(state, { assetSelected: { $merge: action.payload } }),
    [SET_ASSET_VIEW]: (state, action) =>
      update(state, { assetView: { $set: action.payload } }),
    [GET_HISTORICAL_METRICS]: (state, action) =>
      update(state, { historicalMetrics: { $set: action.payload } }),
    [GET_REFILL_METRICS]: (state, action) =>
      update(state, {
        refillMetrics: { $set: action.payload },
      }),
    [GET_TEMPERATURE_METRICS]: (state, action) =>
      update(state, { temperatureMetrics: { $set: action.payload } }),
    [GET_TILT_METRICS]: (state, action) =>
      update(state, { tiltMetrics: { $set: action.payload } }),
    [GET_ANALYTICS]: (state, action) =>
      update(state, { analyticsMetrics: { $set: action.payload } }),
    [SET_ASSET_STATUS]: (state, action) => {
      if (state.assetSelected != null) {
        return {
          ...state,
          assetSelected: {
            ...state.assetSelected,
            active: action.payload.status,
          },
        };
      }
      return state;
    },
  },
  initialState
);

export default reducer;
