import React, { useEffect, useRef } from "react";
import Filter from "./filter";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import helpers from "../../../utils/helpers";
import { useState } from "react";
import RefillsTable from "./RefillsTable";
import TotalRefills from "./TotalRefills";
import ViewButtons from "./ViewButtons";

const Timeserie = (props) => {
  const chartComponent = useRef({});
  const { strings, series, onGetMetrics, resourceId, loggedUser } = props;

  const [view, setView] = useState("timeseries");
  const [chart, setChart] = useState({
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f} Lts",
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}"><strong>Start: </strong>{point.startTime}</span><br/> ' +
        '<span style="color:{point.color}"><strong>End: </strong>{point.endTime}</span><br/> ' +
        '<span style="color:{point.color}"><strong>Duration (hrs): </strong>{point.duration}</span><br/> ' +
        '<span style="color:{point.color}"><strong>Initial Volume: </strong>{point.initialVolume}</span><br/> ' +
        '<span style="color:{point.color}"><strong>End Volume: </strong>{point.endVolume}</span><br/> ' +
        "<b>{point.y:.2f} (Lts)</b> Total<br/>",
    },
    series: series || [],
  });

  useEffect(() => {
    if (series.length === 0) {
      onGetMetrics(loggedUser.clientId, resourceId, 3);
    }
  }, []);
  useEffect(() => {
    setChart((prev) => ({
      ...prev,
      series,
    }));
  }, [series]);

  useEffect(() => {
    if (chartComponent.current && chartComponent.current.chart) {
      const chart = chartComponent.current.chart;
      chart.reflow(false);
    }
  }, [chart.series]);

  return (
    <div className='row'>
      <section className='col-lg-12 connectedSortable ui-sortable'>
        <div className='box box-info'>
          <div
            className='box-header ui-sortable-handle'
            style={{ cursor: "move", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i className='fa fa-envelope' style={{ marginRight: "10px", fontSize: "18px" }} />
              <h3 className='box-title' style={{ margin: 0 }}>Asset Refills</h3>
            </div>
            <ViewButtons view={view} setView={setView} />
          </div>
          <div className='box-body'>
            <Filter
              {...{ strings, onGetMetrics, resourceId }}
              userId={loggedUser.clientId}
            />
            {view === "timeseries" &&
              <React.Fragment>
                <HighchartsReact
                  highcharts={Highcharts}
                  ref={chartComponent}
                  options={chart}
                />
              </React.Fragment>
            }
            {view === "table-refills" &&
              <RefillsTable data={series} />
            }
            {view === "total-refills" &&
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <TotalRefills data={series} />
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
};

export default Timeserie;
