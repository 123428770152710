import React, { useState } from "react";
import { Input, Button, Col, Row, Select } from "antd";

import "antd/lib/icon/style/css";
import "antd/lib/input/style/css";
import "antd/lib/button/style/css";
import "antd/lib/col/style/css";
import "antd/lib/row/style/css";
import "antd/lib/select/style/css";

const { Option } = Select;

const Search = (props) => {
  const { onSubmit, filter } = props;
  const [filters, setFilters] = useState(filter);
  const [showSearch, setShowSearch] = useState(false);

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSelectChange = (value, name) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onFilter = () => {
    onSubmit(filters);
  };

  return (
    <div className='col-md-12'>
      <div className='box box-warning zd-box-pointer'>
        <div
          className='box-header with-border'
          onClick={() => setShowSearch(!showSearch)}
        >
          <h3 className='box-title'>Filter Assets</h3>
          <div className='box-tools pull-right'>
            <button
              type='button'
              className='btn btn-box-tool'
              onClick={() => setShowSearch(!showSearch)}
            >
              <i className={"fa fa-" + (showSearch ? "minus" : "plus")} />
            </button>
          </div>
        </div>
        <div className='box-body' style={showSearch ? {} : { display: "none" }}>
          <div>
            <Row gutter={24} type='flex' className='zdrow'>
              <Col span={6} className='gutter-row'>
                <Input
                  placeholder='123ABC'
                  name='ident'
                  addonBefore='Ident'
                  onChange={onChange}
                  allowClear
                />
              </Col>
              <Col span={6} className='gutter-row'>
                <Input
                  placeholder='Campana'
                  name='location_descr'
                  addonBefore='Location'
                  {...{ onChange }}
                  allowClear
                />
              </Col>
              <Col span={6}>
                <Input
                  placeholder='ChampionX'
                  name='name'
                  addonBefore='Name'
                  {...{ onChange }}
                  allowClear
                />
              </Col>
              <Col span={6}>
                <Input
                  placeholder='Assure'
                  name='content'
                  addonBefore='Content'
                  {...{ onChange }}
                  allowClear
                />
              </Col>
              <Col span={6}>
                <Input
                  placeholder='Biocida'
                  name='content_type'
                  addonBefore='Class'
                  {...{ onChange }}
                  allowClear
                />
              </Col>
              <Col span={6} className='gutter-row'>
                <Select
                  placeholder='Select Automation Mode'
                  name='operationMode'
                  onChange={(value) => onSelectChange(value, 'operationMode')}
                  allowClear
                  style={{ width: '100%' }}
                >
                  <Option value='static'>Static</Option>
                  <Option value='operated'>Operated</Option>
                  <Option value='automated'>Automated</Option>
                </Select>
              </Col>
              <Col span={6} className='gutter-row'>
                <Select
                  placeholder='Select App Point Status'
                  name='appPointStatus'
                  onChange={(value) => onSelectChange(value, 'appPointStatus')}
                  allowClear
                  style={{ width: '100%' }}
                >
                  <Option value='Activo'>Activo</Option>
                  <Option value='Suspension'>Suspension</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              <Col
                span={6}
                offset={9}
                style={{ textAlign: "left", marginTop: "20px" }}
              >
                <Button
                  type='primary'
                  className='btn btn-info pull-left'
                  htmlType='button'
                  icon='search'
                  onClick={onFilter}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
