import React, { Component } from "react";
import Translate from "../shared/i18n/Translate";
import api from "../../utils/api";
import Historic from "./charts/historic";
import Alert from "../common/alert";
import Timelines from "./timelines";
import Detail from "./detail";
import Analytics from "./analytics";
import Card from "./Card";
import ManualRead from "./ManualRead";
import DipstickCalibration from "./DipstickCalibration";
import Timeserie from "./charts/Timeserie";

class AssetPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      timerId: null,
      modal: false,
      days: 2,
    };
    this.getAsset = this.getAsset.bind(this);
    this.handleSetQuickEvent = this.handleSetQuickEvent.bind(this);
    this.getTimelineType = this.getTimelineType.bind(this);
    this.renderNotification = this.renderNotification.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onUpdateDays = this.onUpdateDays.bind(this);
    this.timerAsset = null;
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.getAsset();
    this.timerAsset = setInterval(() => {
      this.getAsset();
    }, 30000); // TIMER de 30 segundos
  }

  getAsset() {
    this.props.getAsset(this.props.assetId).then(() =>
      this.setState({
        loading: false,
      })
    );
  }

  pushControlMenu() {
    var body = document.body;
    if (body.className.indexOf("control-sidebar-open") === -1) {
      body.className += " control-sidebar-open";
    } else {
      body.className = body.className.replace(" control-sidebar-open", "");
    }
  }
  componentWillUnmount() {
    clearInterval(this.timerAsset);
    if (body.className.indexOf("control-sidebar-open") !== -1) {
      body.className = body.className.replace(" control-sidebar-open", "");
    }
  }
  handleSetQuickEvent() {
    const { asset, loggedUser } = this.props;
    const data = {
      client_id: asset.client_id,
      timelineType: parseInt(this.type.value),
      timeline_user: loggedUser.email,
      decr: this.description.value,
      aud_time: new Date().getTime(),
      ident: loggedUser.name,
    };
    this.description.value = "";

    api.setQuickEvent(asset.id, data).then(() => this.getAsset());
  }
  getTimelineType(id) {
    if (id == 0) return "Device Creation";
    if (id == 1) return "Device Assigned";
    if (id == 2) return "Device Removed";
    if (id == 4) return "Note";
    if (id == 5) return "Event";
    return "";
  }
  renderNotification(asset) {
    if (!asset.hasNotifications) {
      return null;
    }
    let msgs = asset.notificationMessages.split("@");
    msgs.shift();
    return (
      <div>
        <Alert
          desc={msgs.join("<br>")}
          type='warning'
          closable={true}
          className='top-alert'
        />
      </div>
    );
  }

  openModal(status) {
    this.setState({ modal: status });
  }

  renderActive(asset) {
    if (asset.active) {
      return null;
    }
    let msgs =
      "The asset is disabled, this means that the messages coming from the measurement devices will not be processed until it is activated";

    return (
      <div>
        <Alert
          desc={msgs}
          type='warning'
          closable={true}
          className='top-alert'
        />
      </div>
    );
  }

  onUpdateDays(daysSelected) {
    this.setState({
      days: daysSelected,
    });
  }

  render() {
    const {
      asset,
      assetView,
      loggedUser,
      assetId,
      strings,
      historicalMetrics,
      getHistoricalMetrics,
      refillMetrics,
      getRefillMetrics,
      getAsset,
    } = this.props;

    if (this.state.loading) {
      return null;
    }
    const { values, last_read } = asset;

    if (!asset.SensorStatus) {
      asset.SensorStatus = {};
    }
    return (
      <div>
        {this.renderActive(asset)}
        {this.renderNotification(asset)}
        <section className='content-header  '>
          <h1>
            {asset.decr}
            <small style={{ marginLeft: "10px" }}> {asset.location.decr}</small>
          </h1>

          <a data-toggle='control-sidebar'>
            <i
              className='fa fa-gears devicePanelIcon'
              onClick={this.pushControlMenu}
            ></i>
          </a>
        </section>
        <section className='content'>
          <div className='row'>
            <Card values={values.values} {...{ strings }} />
          </div>
          {assetView == "details" && <Detail {...this.props} />}
          {assetView == "dipstick" && <DipstickCalibration {...this.props} />}
          <Timelines {...this.props} getAsset={this.getAsset} />
          {assetView == "analytics" &&
            <Analytics
              {...{ loggedUser, strings }}
              series={historicalMetrics}
              seriesDefault={asset.timeSerie}
              onGetMetrics={getHistoricalMetrics}
              resourceId={assetId}
              strings={strings}
              yaxisMin={10}
              yaxisTickInterval={10} />
          }
          {assetView == "details" && (
            <div>
              <div className='row'>
                <section className='col-lg-12 connectedSortable ui-sortable'>
                  <div className='box box-info'>
                    <div
                      className='box-header ui-sortable-handle'
                      style={{ cursor: "move" }}
                    >
                      <i className='fa fa-envelope' />
                      <h3 className='box-title'>Historic Values</h3>
                      <div className='pull-right box-tools'>
                        <button
                          className='btn btn-primary btn-xs'
                          onClick={() => this.openModal(true)}
                        >
                          Add manual reading
                        </button>
                      </div>
                    </div>
                    <div className='box-body'>
                      <Historic
                        series={historicalMetrics}
                        seriesDefault={asset.timeSerie}
                        onGetMetrics={getHistoricalMetrics}
                        {...{ loggedUser, strings }}
                        onUpdateDays={this.onUpdateDays}
                        resourceId={assetId}
                        yaxisMin={10}
                        yaxisTickInterval={10}
                      />
                    </div>
                    <ManualRead
                      open={this.state.modal}
                      onGetMetrics={getHistoricalMetrics}
                      days={this.state.days}
                      {...{ asset, loggedUser, getAsset, assetId }}
                      onClose={() => this.openModal(false)}
                    />
                  </div>
                </section>
              </div>
              <Timeserie
                series={refillMetrics}
                onGetMetrics={getRefillMetrics}
                resourceId={assetId}
                {...{ strings, loggedUser }}
                yaxisMin={10}
                yaxisTickInterval={10}
              />
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default Translate(AssetPresentation, "Asset");
