import React from 'react';
import Historic from './charts/historic';

class Analytics extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>
      <div className="row">
        <section className="col-lg-12 connectedSortable ui-sortable">
          <div className="box box-info">
            <div className="box-header ui-sortable-handle" style={{ cursor: 'move' }}>
              <i className="fa fa-area-chart" />
              <h3 className="box-title">Analytics</h3>
              <div className="pull-right box-tools"></div>
            </div>
            <div className="box-body">
              <Historic {...this.props} />
            </div>
          </div>
        </section>
      </div>
    </div>
  }
}

module.exports = Analytics;