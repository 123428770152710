import { createAction } from "redux-actions";
import {
  SET_ASSET,
  MERGE_ASSET,
  SET_ASSET_VIEW,
  GET_HISTORICAL_METRICS,
  GET_REFILL_METRICS,
  GET_TEMPERATURE_METRICS,
  GET_TILT_METRICS,
  GET_ANALYTICS,
  SET_ASSET_STATUS,
} from "../actionTypes/assetSelectedActions";
import api from "../../utils/api";
import {
  transformSeries,
  transformSerie,
  transformToColumn,
} from "../../utils/transformers";

export const setAsset = createAction(SET_ASSET);
export const mergeAsset = createAction(MERGE_ASSET);
export const setAssetView = createAction(SET_ASSET_VIEW);
export const historicalMetrics = createAction(GET_HISTORICAL_METRICS);
export const refillMetrics = createAction(GET_REFILL_METRICS);
export const temperatureMetrics = createAction(GET_TEMPERATURE_METRICS);
export const tiltMetrics = createAction(GET_TILT_METRICS);
export const analyticsMetrics = createAction(GET_ANALYTICS);
export const putUpdateStatus = createAction(SET_ASSET_STATUS);

export const fetchAsset = (id) => {
  return (dispatch, getState) => {
    return _getAsset(id).then((data) => {
      data = {
        ...data,
        timeSerie: transformSeries(data.timeSerie),
      };
      dispatch(setAsset(data));
    });
  };
};

export const getHistoricalMetrics = (id, idAsset, days) => {
  return (dispatch) => {
    return _getHistoricalMetrics(id, idAsset, days)
      .then((response) => {
        dispatch(historicalMetrics(transformSeries(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getRefillMetrics = (id, idAsset, days) => {
  return (dispatch) => {
    return api
      .getAssetRefill(id, idAsset, days)
      .then((response) => {
        dispatch(refillMetrics(transformToColumn(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getTemperatureMetrics = (id, days) => {
  return (dispatch) => {
    return _getTemperatureMetrics(id, days)
      .then((response) => {
        dispatch(temperatureMetrics(transformSeries(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getTiltMetrics = (id, days) => {
  return (dispatch) => {
    return _getTiltMetrics(id, days)
      .then((response) => {
        dispatch(tiltMetrics(transformSeries(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAnalytics = (id, serialNumber, days) => {
  return (dispatch) => {
    return _getAnalyticsMetrics(id, serialNumber, days)
      .then((response) => {
        let series = [];
        if (response.deviceValues && response.deviceValues.length > 0) {
          series = transformSeries(response.deviceValues, "spline");
          if (series[0].name == "dist") {
            series.shift();
          }
        }
        if (response.assetGallons && response.assetGallons.length > 0) {
          const serie = transformSerie(
            response.assetGallons,
            "gallons",
            "spline"
          );
          series.push(serie);
        }
        let data = {};
        if (
          response.deviceHealthIssues &&
          response.deviceHealthIssues.length > 0
        ) {
          const health = _processFlags(
            response.deviceHealthIssues,
            "Health",
            "#333"
          );
          series.push(health);
        }
        if (
          response.deviceInvalidReadings &&
          response.deviceInvalidReadings.length > 0
        ) {
          const Readings = _processFlags(
            response.deviceInvalidReadings,
            "Reading",
            "#333",
            false,
            "circlepin"
          );
          series.push(Readings);
        }
        data.series = series;
        dispatch(analyticsMetrics(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateAsset = (clientId, assetId, status) => {
  return (dispatch) => {
    return _updateAssetStatus(clientId, assetId, status).then(() => {
      dispatch(putUpdateStatus({ status: status == "activate" }));
    });
  };
};

const _getAsset = (id) => {
  return api.getAsset("1", id);
};

const _getHistoricalMetrics = (idUser, idAsset, days) => {
  return api.getAssetTimeSerie(idUser, idAsset, days);
};

const _getTemperatureMetrics = (id, days) => {
  return api.getAssetTemperatureMetrics(id, days);
};

const _getTiltMetrics = (id, days) => {
  return api.getAssetTiltMetrics(id, days);
};
const _getAnalyticsMetrics = (id, serialNumber, days) => {
  return api.getAnalyticsMetrics(id, serialNumber, days);
};

const _processFlags = (
  datas,
  name = "",
  color = "",
  health = true,
  shape = "squarepin",
  type = "flags"
) => {
  let series = {};
  if (datas.length > 0) {
    series = {
      type: type,
      name: name,
      color: color,
      shape: shape,
      data: [],
      showInLegend: false,
    };
    datas.map((data) => {
      const time = data["time"].toFixed(3).toString().replace(".", "");
      let aux;
      if (health) {
        aux = {
          x: parseFloat(time),
          title: data.message,
          text: `${data.health} ${data.message_desc}`,
        };
      } else {
        aux = {
          x: parseFloat(time),
          title: data.att,
          text: data.message,
        };
      }
      series.data.push(aux);
    });
  }
  return series;
};

const _updateAssetStatus = (clientId, assetId, status) => {
  //assets/1/59/disable
  return api.putAsset(clientId, assetId, status);
};
