import { connect } from "react-redux";
import { push } from "react-router-redux";
import AssetPresentation from "./AssetPresentation.js";
import * as assetSelectedActions from "../../redux/actionCreators/assetSelectedActionCreators";
import routes from "../../utils/routes";

const mapStateToProps = function (state, ownProps) {
  return {
    asset: state.assetSelected.assetSelected,
    assetView: state.assetSelected.assetView,
    assetId: ownProps.match.params.id,
    loggedUser: state.loggedUser,
    historicalMetrics: state.assetSelected.historicalMetrics,
    refillMetrics: state.assetSelected.refillMetrics,
    temperatureMetrics: state.assetSelected.temperatureMetrics,
    tiltMetrics: state.assetSelected.tiltMetrics,
    analyticsMetrics: state.assetSelected.analyticsMetrics,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    goToHome: () => dispatch(push(routes.routeHome())),
    goToAssets: () => dispatch(push(routes.routeAssets())),
    getAsset: (id) => dispatch(assetSelectedActions.fetchAsset(id)),
    getHistoricalMetrics: (id, idAsset, day) =>
      dispatch(assetSelectedActions.getHistoricalMetrics(id, idAsset, day)),
    getRefillMetrics: (id, idAsset, day) =>
      dispatch(assetSelectedActions.getRefillMetrics(id, idAsset, day)),
    getTemperatureMetrics: (id, day) =>
      dispatch(assetSelectedActions.getTemperatureMetrics(id, day)),
    getTiltMetrics: (id, day) =>
      dispatch(assetSelectedActions.getTiltMetrics(id, day)),
    goToDevice: (id) => dispatch(push(routes.routeDevice(id))),
    getAnalytics: (id, serialNumber, day) =>
      dispatch(assetSelectedActions.getAnalytics(id, serialNumber, day)),
    setAssetView: (view) => dispatch(assetSelectedActions.setAssetView(view)),
  };
};

const AssetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetPresentation);

export default AssetContainer;
