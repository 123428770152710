export default {
    Login: {
      logIn: 'Ingresar',
      email: 'Email',
      password: 'Contraseña',
      badLoginMsg: 'Email o password incorrecto. Intentelo nuevamente.'
    },
    Breadcrumb: {
      home: "Inicio",
      inbox: "SMS Inbox",
      devices:"Dispositivos",
      ports:"Puertos",
      junk: "SMS Papelera",
      users: "Usuarios",
      newOperator: "Nuevo Usuario",
      citizens: "Ciudadanos",
      conversation: "Conversación"
    },
    Inbox: {
      inbox: "Inbox",
      junk: "Papelera",
      newSms: "Nuevo SMS",
      newMessages: "Nuevos mensajes",
      search: "Buscar",
      folders: "Carpetas",
      unreadFilter: "Filtrar por no leidos",
      insertTag: "Ingresa un tag..."
    },
    Header: {
      logOut: "Cerrar sesión"
    },
    Footer: {
      version:"Versión"
    },
    Dashboard: {
      dashboard:"Dashboard",
      locations:"Ubicaciones",
      mode:"Modo",
      status:"Estado",
      type:"Tipo",
      assetsNot:"Assets sin reportar",
      assets:"Assets",
      manufacter:"Manufacter",
      all:"Todo",
      actives:"Activos"
    },
    NavigationMenu: {
      dashboard:"Dashboard",
      assets:"Assets",
      erpIntegration:"ERP integration",
      locations:"Ubicaciones",
      assetTypes:"Asset Types",
      accounts:"Accounts",
      search:"Buscar...",
      online:"En linea",
      new:"Nuevo",
      mainNavigation:"PRINCIPAL",
      operationsConsole:"Consola de operaciones",
      inbox:"Inbox",
      admin:"ADMIN",
      operators:"Operadores",
      status:"Estados",
      tags:"Tags",
      devops:"DevOps",
      deep:"PROFUNDIDAD",
      ports:"Puertos",
      users:"Usuarios",
      keys:"Llaves",
      parking:"PARKING",
      slots:"Slots",
      bikesTitle:"BICICLETAS",
      bikes:"Bicicletas",
      waste:"RESIDUOS",
      trucks:"Camiones",
      bins:"Papeleras",
      iotdevices:"DISPOSITIVOS IOT",
      devices:"Dispositivos",
      gateways:"Gateways",
      mobileSupport:"Soporte Mobile",
      supportSessions:"Sesiones de Soporte",
      roles:"Roles",
      datasources: "Data Sources",
      taskTemplates: "Templates Tareas"
    },
    ControlsMenu:{
      edit:"Editar",
      disable:"Desactivar",
      activate:"Activar",
      detail:"Detalles",
      messages:"Mensajes",
      reading:"Lecturas",
      information:"Información",
      debug:"Debug",
      engineering:"Ingeniería",
      hardwareVersion:"Versión de hardware",
      firmwareVersion:"Versión de firmware",

      layoutOptions:"Opciones de Layout",
      inConstruction:"En construcción",
      newConversationsFirst:"Nuevas conversaciones primero",
      putNewConversationFirst:"Ubicar nuevas conversaciones en el primer lugar de la pantalla.",
      newMessagesArrives:"Nuevos mensajes que lleguen",
      activateConversationsFirst:"Ubicar las conversaciones con nuevos mensajes en el primer lugar.",
      cleaning:"Limpieza",
      removeConversations:"Eliminar conversaciones",
      removeInactiveConversations:"Eliminar conversaciones inactivas (30 min. de inactividad)",
      minimizeAssignedConversations:"Minimizar conversaciones asignadas",
      minimizeConversationsBeingAnswered:"Minimizar la conversacion que esta siendo respondida por otro agente."
    },
    Operators: {
      operatorManagement:"Gestión de operadores",
      newOperator:"Nuevo Operador",
      activeOperators:"Operadores Activos",
      inactiveOperators:"Operadores Inactivos",
      search:"Buscar",
      status:"Estado",
      active:"Activos",
      inactive:"Inactivos"
    },
    OperatorsManage: {
      newOperator:"Nuevo Operador",
      editOperator:"Editar Operador",
      name:"Nombre",
      email:"Email",
      rol:"Rol",
      timezone:"Zona Horaria",
      password:"Contraseña",
      active:"Activo",
      cancel:"Cancelar",
      save:"Guardar",
      badDataMsg: "Debes ingresar un ",
      badDataMsgValid:" válido",
      account:"Account"
    },
  Clients: {
        clientManagement:"Client Management",
        newClient:"New Client",
        activeClients:"Active Clients",
        inactiveClients:"Inactive Clients",
        search:"Search",
        status:"Status",
        active:"Active",
        inactive:"Inactive"
      },
      ClientsManage: {
        newClient:"New Client",
        generateToken:"Generate",
        editClient:"Edit Client",
        company:"Company",
        assetToken:"Asset App Key",
        adminApp:"Admin App Key",
        iotApp:"IoT App Key",
        adminAppActive:"Admin App",
          inputIoTAppActive:"IoT App",
          assetTokenActive:"Asset App",
          deepTokenActive:"Deep App",
parkingTokenActive:"Parking App",
bikesTokenActive:"Bikes App",
          wasteTokenActive:"Waste App",
        deepToken:"Deep App Key",
        parkingToken:"Parking App Key",
        bikesToken:"Bikes App Key",
        wasteToken:"Waste App Key",
        name:"Name",
        email:"Email",
        rol:"Rol",
        timezone:"Timezone",
        password:"Password",
        active:"Active",
        cancel:"Cancel",
        save:"Save",
        badDataMsg: "You must enter a ",
        badDataMsgValid:" Valid",
        create:"Crear"
      },
    Ports: {
      portManagement:"Puertos",
      newPort:"Nuevo Puerto",
      id:"id",
      name:"Nombre",
      delete:"Borrar"
    },
    PortsManage: {
      newPort:"Nuevo Puerto",
      editPort:"Editar Puerto",
      name:"Nombre",
      lat:"Lat",
      ports:"Puertos",
      lon:"Lon",
      depth_formula_type:"Depth Formula Type",
      mapid:"Mapid",
      sensors:"Sensores",
      sensor:"Sensor",
      priority:"Prioridad",
      offset:"Offset",
      cancel:"Cancelar",
      save:"Guardar",
      offset:"Offset",
      add:"Agregar",
      remove:"Remover",
      badDataMsg: "Debes ingresar un ",
      badDataMsgValid:" válido",
    },
    DeviceManage: {
      newDevice:"Nuevo Dispositivo",
      editDevice:"Editar Dispositivo",
      registerDevice:"Registering",
      addEvent:"Agregar Evento",
      name:"Nombre",
      email:"Email",
      rol:"Rol",
      timezone:"Zona Horaria",
      password:"Contraseña",
      authorized:"Autorizado",
      cancel:"Cancelar",
      save:"Guardar",
      badDataMsg: "Debes ingresar un ",
      badDataMsgValid:" válido",

      cmmunicationType:"Tipo de comunicación",
      descr:"Descripción",
      deviceType:"Tipo de dispositivo",
      lat:"Latitud",
      lon:"Longitud"
    },
    NotFoundPage: {
      inConstruction:"En construcción"
    },
    AppStatus: {
          title:"Platform Status"
        },
    Devices: {
      home:"Inicio",
      deviceManagement:"Gestión de dispositivos",
      status:"Estado",
      active:"Activo",
      authorized:"Autorizado",
      deviceName:"Nombre",
      deviceSn:"SN",
      gateway:"Gateway",
      lastMsgTime:"Fecha Último Mensaje",
      registerDeviceSingle:"Registrar un dispositivo",
      reporting: "Reporte",
      lastRead: "Last read",
      reading: "Lectura",
      healthy: "Salud"
    },
    Device: {
      lastValues:"Últimos Valores",
      lastMessage:"Último Mensaje",
      battery:"Batería",
      value:"Valor",
      sn:"SN",
      name:"Nombre",
      type:'Tipo',
      source:"Fuente",
      gateway:"Gateway",

      newOperator:"Nuevo Operator",
      editOperator:"Editar Operador",
      name:"Nombre",
      email:"Email",
      rol:"Rol",
      timezone:"Zona Horaria",
      password:"Contraseña",
      active:"Activo",
      cancel:"Cancelar",
      save:"Guardar",
      badDataMsg: "Debes ingresar un ",
      badDataMsgValid:" válido",

      details:"Detalles",
      messages:"Mensajes",
      reading:"Lecturas",
      information:"Información",
      debug:"Debug",
      engineering:"Ingeniería",
      messageTime:"Tiempo Msj.",
      protocol:"Protocolo",
      addEvent:"Agregar Evento",
      showLast: 'Ver los últimos',
      days: 'Días',
      health: 'Salud Dispositivo'
    },
    PortDeleteModal: {
      areYouSure:"Esta seguro que desea eliminar el puerto ",
      yes:"Si",
      no:"No"
    },
    AssetTypeDeleteModal: {
      areYouSure:"Are you sure you want to delete the asset type ",
      yes:"Yes",
      no:"No"
    },
    AssetTypes: {
      id:"Id",
      description:"Description",
      long:"Height",
      width:"Width",
      assetTypes:"Asset Types",
      newAssetType:"New Asset Type",
      activeAssetTypes:"Active AssetTypes",
      inactiveAssetTypes:"Inactive AssetTypes",
      search:"Search",
      status:"Status",
      active:"Active",
      csa:"CSA",
      inactive:"Inactive",
      depth:"Depth",
      notification: "Notificaciones",
      class: "Clase",
      description: "Descripción",
      manufacturer: "Fabricante"
    },
    AssetTypesManage: {
      assetTypes:"Asset Types",
      newAssetType:"New AssetType",
      editAssetType:"Edit AssetType",
      id:"Id",
      description:"Description",
      long:"Height",
      width:"Width",
      depth:"Depth",
      csa:"CSA",
      cancel:"Cancel",
      save:"Save",
      badDataMsg: "You must enter a ",
      badDataMsgValid:" Valid",
      notification: "Notificaciones & Alertas",
      galons: "Galones",
      levelhight: "",
      levellow: "",
      temphigh: "",
      templow: "",
      batery: "",
      tilthigh: "",
      tiltlow: "",
      frecuence: "",
      class: "Clase",
      selectClass: "Seleccione Clase",
      metrics: "Métricas",
      manufacture: "Fabricante",
      manufacturecode: "Código",
      manufacturedesc: "Descripción",
      selectManufacture: "Seleccione Fabricante",
      add_notification: "Nueva Notificación",
      field: "Campo",
      compare: "Comparar",
      value: "Valor",
      severity: "Severity",
      title: "Título",
      text: "Texto",
      short_text: "Texto corto",
      transition: "Transición",
      alerts: (key) => {
        const c = {
          "volumen": "Volumen",
          "fill": "Fill",
          "temperature": "Temperature",
          "status": "Status",
          "health": "Health",
          "report": "Report",
          "compr_suction_pressure":"compr_suction_pressure",
          "compr_discharge_pressure":"compr_discharge_pressure",
          "comp_oil_level":"comp_oil_level",
          "comp_liquid_sep_level":"comp_liquid_sep_level",
          "comp_oil_pressure":"comp_oil_pressure",
          "comp_temp_bco1": "comp_temp_bco1",
          "comp_temp_bco2": "comp_temp_bco2",
          "eng_oil_pressure": "eng_oil_pressure",
          "eng_temperature": "eng_temperature",
          "eng_oil_level": "eng_oil_level",
          "eng_emergency_stop": "eng_emergency_stop",
          "eng_manual_stop": "eng_manual_stop",
          "eng_velocity": "eng_velocity",
          "eng_pmr": "eng_pmr",
          "flow_static_pressure": "flow_static_pressure",
          "flow_diferential_pressure": "flow_diferential_pressure",
          "flow_temperature": "flow_temperature",
          "flow_rate_actual": "flow_rate_actual",
          "enginestatus": "Estado del motor"
        }
        return c[key] ? c[key] : "";
      },
      confirmDelete: "Esta seguro?",
      max_capacity: "Max capacidad"
    },
    LocationDeleteModal: {
      areYouSure:"Are you sure you want to delete the location ",
      yes:"Yes",
      no:"No"
    },
    Locations: {
      id:"Id",
      description:"Description",
      lat:"Latitude",
      lon:"Longitude",
      locations:"Locations",
      newLocation:"New Location",
      activeLocations:"Active Locations",
      inactiveLocations:"Inactive Locations",
      search:"Search",
      status:"Status",
      active:"Active",
      inactive:"Inactive"
    },
    LocationsManage: {
      locations:"Locations",
      newLocation:"New Location",
      editLocation:"Edit Location",
      id:"Id",
      description:"Description",
      lat:"Latitude",
      lon:"Longitude",
      depth:"Depth",
      csa:"CSA",
      cancel:"Cancel",
      save:"Save",
      badDataMsg: "You must enter a ",
      badDataMsgValid:" Valid",
    },
    Assets: {
      id:"Id",
      location:"Location",
      type:"Type",
      description:"Description",
      status:"Status",
      assets:"Assets",
      newAsset:"New Asset",
      metrics: "Métricas",
      fill: "Fill Level",
      temperature: "Temperatura"
    },
    AssetDeleteModal: {
      areYouSure:"Are you sure you want to delete the asset ",
      yes:"Yes",
      no:"No"
    },
    Asset:{
      showLast: 'Ver últimos',
      days: 'Días',
      assetprefix: "Asset",
      manufacture: "Manufacture",
      manufacturecode: "Manufacture code",
      manufacturedesc: "Manufacture desc"
    },
    AssetManage: {
      newAsset:"New Asset",
      editAsset:"Edit Asset",
      ports:"Assets",
      lat:"Lat",
      lon:"Lon",
      sensors:"Sensors",
      sensor:"Sensor",
      priority:"Priority",
      offset:"Offset",
      cancel:"Cancel",
      save:"Save",
      offset:"Offset",
      add:"Add",
      remove:"Remove",

      decr:"Description",
      ident:"Ident",
      location:"Location",
      assetType:"Asset Type",
      basic:"Basic",
      sensorRequired:"You must select at least one sensor",

      badDataMsg: "You must enter a valid ",
      badDataMsgValid:" Valid",
      notifications: "Notificaciones activo"
    },
    DataSource: {
      title: "DataSources",
      newDataSource: "Nuevo DataSource",
      new: "Nuevo",
      edit: "Editar",
      id: "ID",
      name: "Nombre",
      type: "Tipo",
      externalid: "ID Externo",
      lastseen: "Visto",
      authorized: "Autorizado",
      home: "Inicio"
    },
    Tag: {

    },
    Common: {
      areYouSure:"Estas seguro de eliminar",
      yes:"Si",
      no:"No"
    },
    TasksTemplates: {
      taskTemplates: 'Task Templates',
      newTask: 'New Task Template',
      editTask: 'Edit Task Template',
      id: 'ID',
      descr: 'Description',
      code: 'Code',
      save: 'Save',
      cancel: 'Cancel',
      home: 'Home',
      yes: 'Yes',
      no: 'No',
      areYouSure: 'Are you sure you want to delete?',
      confirm: 'Confirm'
    },
    Tasks: {
      tasks: 'Tasks',
      newTask: 'New Task',
      editTask: 'Edit Task',
      id: 'ID',
       filtersearch: 'Filter Tasks',
      save: 'Save'
    }
}
