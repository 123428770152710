import React, { Component } from "react";
import helpers from "../../utils/helpers";
import ListControls from "../../lib/list-controls/ListControls";
import Metrics from "./Metrics";
import { Tooltip } from "antd";

class List extends Component {
  constructor(props) {
    super(props);
    this.renderNotification = this.renderNotification.bind(this);
    this.lastReadDuration = this.lastReadDuration.bind(this);
  }

  renderStatus(item, strings, loggedUser, index) {
    const { mode, device_local_status, last_read_duration, last_read } = item;
    const isOffline = device_local_status === "Not Reporting";

    if (mode === "automated") {
      if (isOffline) {
        const title = `The device must report every ${this.lastReadDuration(last_read_duration)}. The last report was ${helpers._transformUtcToTimezone(last_read, loggedUser.preferences.timezone, true)}`;

        return (
          <React.Fragment>
            <Tooltip title={title}>
              <span className='label label-warning'>Offline</span>
            </Tooltip>
          </React.Fragment>
        );
      }

      return <span className='label label-success'>Online</span>;
    }

    return <span className='label label-success'>Manual</span>;
  }

  lastReadDuration(duration) {
    if (duration >= 86400) {
      //dia
      let d = duration / 86400;
      return `${d.toFixed(1)} d `;
    } else if (duration > 3600 && duration < 86400) {
      //horas
      let hr = duration / 3600;
      return `${hr.toFixed(0)} hr `;
    } else {
      // m
      let m = duration / 60;
      return `${m.toFixed(0)} min `;
    }
  }
  renderNotification(item, index) {
    if (!item.hasNotifications) {
      return <span className='label label-success'>0</span>;
    }
    let msgs = item.notificationMessages.split("@");
    msgs.shift();
    return (
      <React.Fragment>
        <Tooltip
          title={() => {
            return (
              <div
                dangerouslySetInnerHTML={{ __html: msgs.join("<br>") }}
              ></div>
            );
          }}
        >
          <span className='label label-warning'>{msgs.length}</span>
        </Tooltip>
      </React.Fragment>
    );
  }

  render() {
    const {
      assets,
      loading,
      strings,
      loggedUser,
      goToAsset,
      enableNext,
      enablePrevious,
      onChange,
      loadingList,
    } = this.props;
    return (
      <section className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='box box-primary'>
              {}
              <div className='box-body no-padding'>
                <ListControls
                  loading={loadingList}
                  {...{ enableNext, enablePrevious, onChange }}
                />
                <div className='table-responsive mailbox-messages'>
                  <table className='table table-hover table-striped zd-table'>
                    <tbody>
                      <tr>
                        <th>{strings.ident}</th>
                        <th>{strings.description}</th>
                        <th>{strings.location}</th>
                        <th>{strings.type}</th>
                        <th>{strings.content}</th>
                        <th>{strings.class}</th>
                        <th>{strings.metrics}</th>
                        <th>{strings.status}</th>
                        <th>{strings.notification}</th>
                        <th></th>
                      </tr>
                      {assets.length > 0 &&
                        assets.map((op, key) => (
                          <tr
                            className='zd-table-tr'
                            key={`liitem${key}`}
                            onClick={() => {
                              goToAsset(op.id);
                            }}
                          >
                            <td className='mailbox-name'>{op.ident}</td>
                            <td className='mailbox-name'>{op.decr}</td>
                            <td className='mailbox-name'>{op.location.decr}</td>
                            <td className='mailbox-name'>
                              {op.assetType.decr}
                            </td>
                            <td className='mailbox-name'>{op.tank_content}</td>
                            <td className='mailbox-name'>
                              {op.tank_content_type}
                            </td>
                            <td className='mailbox-name'>
                              <Metrics
                                {...{ strings, loggedUser }}
                                asset_class={op.assetType.asset_class}
                                values={op.values.values}
                                minimunInventoryLevel={
                                  op.minimum_inventory_level
                                }
                              />
                            </td>
                            <td className='mailbox-name' align='center'>
                              {this.renderStatus(op, strings, loggedUser, key)}
                            </td>
                            <td className='mailbox-name' align='center'>
                              {this.renderNotification(op, key)}
                            </td>
                            <td className='mailbox-name' width='50'>
                              <div className='btn-group'>
                                <a
                                  data-toggle='modal'
                                  data-target='#AssetDeleteModal'
                                  onClick={(e) => {
                                    this.props.setAssetToDelete(op);
                                    e.stopPropagation();
                                  }}
                                >
                                  <button
                                    type='button'
                                    className='btn btn-default btn-sm'
                                  >
                                    <i className='fa fa-trash'></i>
                                  </button>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='box-footer no-padding'>
                <ListControls
                  loading={loadingList}
                  {...{ enableNext, enablePrevious, onChange }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default List;
