import React, { useEffect } from "react";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";

const AssetsTank = (props) => {
  const {
    strings,
    tank,
    tank_content,
    tank_content_type,
    inyection_status,
    inyection_treatment,
    minimum_inventory_level,
  } = props;

  const [tankOptions, setTankOptions] = useState({
    content: [],
    content_type: [],
    status: [],
    treatment: [],
  });
  useEffect(() => {
    setTankOptions((prev) => ({
      ...prev,
      content:
        tank.content.length > 0
          ? tank.content.map((item) => ({ label: item, value: item }))
          : [],
      content_type:
        tank.contentType.length > 0
          ? tank.contentType.map((item) => ({ label: item, value: item }))
          : [],
      status:
        tank.inyectionStatus.length > 0
          ? tank.inyectionStatus.map((item) => ({ label: item, value: item }))
          : [],
      treatment:
        tank.inyectionTreatment.length > 0
          ? tank.inyectionTreatment.map((item) => ({
              label: item,
              value: item,
            }))
          : [],
    }));
  }, [tank]);

  const handleCreate = (field, stateField, inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setTankOptions((prev) => ({
      ...prev,
      [field]: [...prev[field], newOption],
    }));
    props.onChange(stateField, inputValue);
  };

  const getValue = (list, value) => {
    if (list.length === 0) {
      return null;
    }
    return list.filter((item) => item.value === value);
  };

  return (
    <React.Fragment>
      <div className='form-group'>
        <label className='col-sm-2 control-label'>{strings.content}</label>
        <div className='col-sm-4'>
          <CreatableSelect
            name='tank_content'
            options={tankOptions.content}
            isClearable
            isDisabled={false}
            onChange={(newValue) =>
              props.onChange("tank_content", newValue.value)
            }
            onCreateOption={(value) =>
              handleCreate("content", "tank_content", value)
            }
            value={getValue(tankOptions.content, tank_content)}
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='col-sm-2 control-label'>{strings.class}</label>
        <div className='col-sm-4'>
          <CreatableSelect
            name='tank_content_type'
            options={tankOptions.content_type}
            isClearable
            onChange={(newValue) =>
              props.onChange("tank_content_type", newValue.value)
            }
            onCreateOption={(value) =>
              handleCreate("content_type", "tank_content_type", value)
            }
            value={getValue(tankOptions.content_type, tank_content_type)}
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='col-sm-2 control-label'>{strings.minInventory}</label>
        <div className='col-sm-4'>
          <input
            type='text'
            name='minimum_inventory_level'
            className='form-control'
            value={minimum_inventory_level || ""}
            onChange={(evt) =>
              props.onChange(evt.target.name, evt.target.value)
            }
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='col-sm-2 control-label'>{strings.appPoint}</label>
        <div className='col-sm-4'>
          <CreatableSelect
            name='inyection_status'
            options={tankOptions.status}
            isClearable
            onChange={(newValue) =>
              props.onChange("inyection_status", newValue.value)
            }
            onCreateOption={(value) =>
              handleCreate("status", "inyection_status", value)
            }
            value={getValue(tankOptions.status, inyection_status)}
          />
        </div>
      </div>
      <div className='form-group'>
        <label className='col-sm-2 control-label'>{strings.treatment}</label>
        <div className='col-sm-4'>
          <CreatableSelect
            name='inyection_treatment'
            options={tankOptions.treatment}
            isClearable
            onChange={(newValue) =>
              props.onChange("inyection_treatment", newValue.value)
            }
            onCreateOption={(value) =>
              handleCreate("treatment", "inyection_treatment", value)
            }
            value={getValue(tankOptions.treatment, inyection_treatment)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssetsTank;
