import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import addTreemapModule from "highcharts/modules/treemap";

addTreemapModule(Highcharts);

class Inventory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        type: "treemap",
        height: '100%',
      },
      title: {
        text: "Assets by well zone",
        align: "left",
      },
      tooltip: {
        useHTML: true,
        pointFormat:
          "<b>{point.tooltip}</b>",
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          alternateStartingDirection: true,
          borderColor: "#fff",

          borderWidth: 2,
          dataLabels: {
            style: {
              textOutline: "none",
            },
          },
          levels: [
            {
              level: 1,
              layoutAlgorithm: "sliceAndDice",
              dataLabels: {
                enabled: true,
                align: "left",
                verticalAlign: "top",
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
                formatter: function () {
                  return this.point.name;
                }
              },
            },
          ],
          data: this.props.series || [],
        },
      ],
      time: {
        timezone: "America/Buenos_Aires",
      },
    };
  }

  componentDidMount() {
    let series = this.state.series;
    series[0].data = this.props.series;

    this.setState({
      ...this.state.chart,
      series,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.series !== prevProps.series) {
      let series = this.state.series;
      series[0].data = this.props.series;

      this.setState({
        ...this.state,
        series,
      });
    }
  }

  render() {
    return (
      <section className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='box box-primary'>
              <div className='box-body no-padding'>
                {this.state.series[0].data.length > 0 && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

module.exports = Inventory;
