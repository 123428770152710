import React from "react";
import helpers from "../../utils/helpers";
import Map from "../assets/Maps";
import NewEvent from "./Event";

class Timelines extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.getTimelineType = this.getTimelineType.bind(this);
    this.state = {
      modal: false,
      visibleEventsCount: 2,
      eventsLoaded: false
    };

    this.loadMoreEvents = this.loadMoreEvents.bind(this);
  }
  openModal(status) {
    this.setState({ modal: status });
  }

  getTimelineType(id) {
    if (id == 0) return "Device Creation";
    if (id == 1) return "Device Assigned";
    if (id == 2) return "Device Removed";
    if (id == 4) return "Note";
    if (id == 5) return "Event";
    return "";
  }

  getWhatsAppUrl(asset) {
    const message =
      encodeURIComponent(`Te comparto la ubicación de ${asset.decr}:
     Location: ${asset.location.decr}.
     Codigo: ${asset.assetType.manufacturer_product_code}.
     Content: ${asset.tank_content}.
     https://google.com/maps/place/${asset.location.lat},${asset.location.lon}
    `);

    if (helpers.isMobile()) {
      return `whatsapp://send?text=${message}`;
    }

    return `https://web.whatsapp.com/send?text=${message}`;
  }

  loadMoreEvents() {
    this.setState((prevState) => ({
      visibleEventsCount: prevState.visibleEventsCount + 5,
      eventsLoaded: true,
    }));
  }

  render() {
    const { asset, loggedUser, assetView, setAssetView } = this.props;
    console.log(this.props);
    const { location } = asset;
    const { visibleEventsCount, eventsLoaded } = this.state;

    if (assetView == "analytics") {
      return null;
    }

    const timelineEvents = asset.timeline
      ? helpers.sortByKey(asset.timeline.timeline, "aud_time")
      : [];

    const hasMoreEvents = visibleEventsCount < timelineEvents.length;

    const eventsToDisplay =
      assetView !== "details"
        ? timelineEvents
        : timelineEvents.slice(0, visibleEventsCount);

    return (
      <div>
        <div className='row'>
          <section className='col-lg-7 connectedSortable ui-sortable'>
            <div className='box box-info'>
              <div
                className='box-header ui-sortable-handle'
                style={{ cursor: "move" }}
              >
                <i className='fa fa-envelope' />
                <h3 className='box-title'>Recent History</h3>
                <div className='pull-right box-tools'>
                  <button
                    className='btn btn-primary btn-xs'
                    onClick={() => this.openModal(true)}
                  >
                    Add Event
                  </button>
                </div>
              </div>
              <div className='box-body'>
                <ul className='timeline'>
                  {eventsToDisplay.length > 0 ? (
                    eventsToDisplay.map((tl, i) => (
                      <li key={i}>
                        <i className='fa fa-user bg-aqua' />
                        <div className='timeline-item'>
                          <span className='time'>
                            <i className='fa fa-clock-o' />{" "}
                            {helpers._transformUtcToTimezone(
                              tl.aud_time,
                              loggedUser.preferences.timezone,
                              true
                            )}
                          </span>
                          <h3 className='timeline-header no-border'>
                            <a href='#'>{tl.ident}</a>{" "}
                            {this.getTimelineType(tl.timelineType)}
                          </h3>
                          <div className='timeline-body'>{tl.decr}</div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <div className='timeline-item'>
                        <h3 className='timeline-header no-border'>
                          No events available.
                        </h3>
                      </div>
                    </li>
                  )}
                  <li>
                    <i className='fa fa-clock-o bg-gray' />
                  </li>
                </ul>
                {assetView === "details" && (
                  <div className='text-center' style={{ marginTop: "15px" }}>
                    {!eventsLoaded && hasMoreEvents && (
                      <button
                        className='btn btn-default'
                        onClick={this.loadMoreEvents}
                      >
                        Load More
                      </button>
                    )}
                    {eventsLoaded && (
                      <button
                        className='btn btn-default'
                        onClick={() => setAssetView("timeline")}
                      >
                        View all
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className='col-lg-5 connectedSortable ui-sortable'>
            <div className='box box-info'>
              <div className='box-header'>
                <h3 className='box-title'>{location ? location.decr : ""}</h3>
                <button
                  className='btn pull-right bg-green'
                  onClick={() => window.open(this.getWhatsAppUrl(asset))}
                >
                  Share Location
                </button>
              </div>
              <div className='box-body'>
                <Map listMarkers={location ? [location] : []} onlyMap />
              </div>
            </div>
          </section>
          <NewEvent
            open={this.state.modal}
            {...{ asset, loggedUser }}
            getAsset={this.props.getAsset}
            onClose={() => this.openModal(false)}
          />
        </div>
      </div>
    );
  }
}

module.exports = Timelines;
