import React from "react";
import Title from "../common/title";
import api from "../../utils/api";
import FileSaver from "file-saver";

const Actions = (props) => {
  const {
    strings,
    goToAssetsManage,
    goToAssetsRefill,
    clientId,
    filter,
    onSetView,
    view,
  } = props;
  const exportFile = () => {
    api.getAssetsExport(clientId, filter).then((resp) => {
      FileSaver.saveAs(resp, "assets.xlsx");
    });
  };
  return (
    <Title
      title={strings.assets}
      newActionLabel={strings.newAsset}
      goTo={() => goToAssetsManage()}
    >
      <div className='zd-select-views'>
        <button
          type='button'
          className={`btn btn-default ${view == "list" ? "btn-active" : ""}`}
          onClick={() => onSetView("list")}
        >
          <i className='fa fa-bars'></i>
        </button>
        <button
          type='button'
          className={`btn btn-default ${view == "map" ? "btn-active" : ""}`}
          onClick={() => onSetView("map")}
        >
          <i className='fa fa-globe'></i>
        </button>
        <button
          type='button'
          className={`btn btn-default ${
            view == "inventory" ? "btn-active" : ""
          }`}
          onClick={() => onSetView("inventory")}
          title='Inventory'
        >
          <i className='fa fa-tasks'></i>
        </button>
        <button
          type='button'
          className='btn btn-default'
          onClick={() => exportFile()}
          title='Export Excel'
        >
          <i className='fa fa-download'></i>
        </button>
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => goToAssetsRefill()}
        >
          Import Measurement
        </button>
      </div>
    </Title>
  );
};

export default Actions;
